<template lang="pug">
  .block
    OtaHeader(:validator="validator")
    BAlert(
      show
      variant="warning"
      :title="$t('plan_settings.basic_settings.rakuten.tooltip')"
    ) {{ $t("plan_settings.basic_settings.rakuten.tooltip") }}
    .sections
      TitleForPC(:object="validator.title_pc")
      TitleForMobile(:object="validator.title_mobile")
      BasicPrice(
        :object="validator.basic_price"
        :applied-changes="validator.$model.applied_changes"
        :optional-export-status="validator.$model.optional_export_status"
        :included-export-status="validator.$model.included_export_status"
      )
      DisplayImportance(:object="validator.importance")
      TextForPC(:object="validator.text_pc")
      TextForMobile(:object="validator.text_mobile")
      Closing(:object="validator.closing")
      SalesStartDate(:object="validator.sales_start_date")
      Points(:object="validator.points")
</template>

<script>
  export default {
    props: {
      validator: Object
    },

    components: {
      TitleForPC: () => import("./TitleForPC"),
      TitleForMobile: () => import("./TitleForMobile"),
      BasicPrice: () => import("./BasicPrice"),
      DisplayImportance: () => import("./DisplayImportance"),
      TextForPC: () => import("./TextForPC"),
      TextForMobile: () => import("./TextForMobile"),
      Closing: () => import("./Closing"),
      SalesStartDate: () => import("./SalesStartDate"),
      Points: () => import("./Points"),
      OtaHeader: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/OtaHeader"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .block
    +block

    .sections
      +sections
</style>
